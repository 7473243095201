<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12">
        <v-card class="" elevation="" max-width="">
          <v-card-title class="justify-center"> NUEVA MARCA </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <v-row>
              <v-col cols="12">
                <p>
                  <router-link exact="" style="text-decoration: none" :to="{ name: 'inicioPanel' }">Inicio</router-link>
                  /
                  <router-link exact="" style="text-decoration: none" :to="{ name: 'marcaPanel' }">Marca</router-link>
                  /
                  <router-link exact="" style="text-decoration: none" :to="{ name: 'marcaCrearPanel' }">Crear
                  </router-link>
                </p>
              </v-col>

              <v-col cols="12">
                <v-divider></v-divider>
              </v-col>
            </v-row>

            <v-form @submit.prevent="guardar()" :disabled="processForm">
              <v-row>
                <v-col cols="12">
                  <v-alert type="error" v-model="alertError" dismissible ref="mensajeError">
                    <ul v-for="(errors, index) in listErrors" :key="index">
                      <li v-text="errors[0]"></li>
                    </ul>
                  </v-alert>
                </v-col>

                <v-col cols="12">
                  <v-text-field dense label="Nombre (*)" v-model="marca.nombre"></v-text-field>
                </v-col>

                <v-col cols="12">
                  <v-select dense item-text="texto" item-value="valor" :items="cmbEstado" label="Estado(*)" outlined
                    v-model="marca.estado"></v-select>
                </v-col>

                <v-col cols="12" class="text-center">
                  <v-btn class="mr-2" type="submit" :loading="processForm" color="success" large="">
                    <v-icon left="" large="">mdi-content-save</v-icon> GUARDAR
                  </v-btn>
                  <v-btn :to="{ name: 'marcaPanel' }" type="button" :loading="processForm" color="error" large="">
                    <v-icon left="" large="">mdi-close</v-icon> CANCELAR
                  </v-btn>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      marca: {
        nombre: "",
        estado: 1,
      },
      cmbEstado: [
        {
          texto: "Habilitado",
          valor: 1,
        },
        {
          texto: "Inhabilitado",
          valor: 0,
        },
      ],
      processForm: false,
      listErrors: [],
      alertError: false,
      loaderImagen: false,
      urlImagen: "",
    };
  },

  methods: {
    guardar() {
      this.alertError = false;
      this.processForm = true;

      this.axios({
        method: "POST",
        url: "api/panel/marca",
        data: this.serialize(this.marca),
      })
        .then((response) => {
          let status = response.status;
          if (status === 200) {
            this.$toasted.success(response.data.data, {
              icon: "mdi-check-bold",
            });
            this.$router.push({
              name: "marcaPanel",
            });
          } else {
            console.log(response);
          }
        })
        .catch((error) => {
          let status = error.response.status;
          if (status === 422) {
            let arrErrors = error.response.data.errors;
            this.listErrors = Object.values(arrErrors);
            this.alertError = true;
            this.$vuetify.goTo(this.$refs.mensajeError);
          } else {
            this.$toasted.error("Ocurrio un error al guardar este registro", {
              icon: "mdi-close",
            });
          }
        })
        .finally(() => {
          this.processForm = false;
        });
    },
  },

  created() {
    if (!this.validarPermiso("marca.crear")) {
      this.$toasted.error("Usted no tiene permisos para esta sección", {
        icon: "mdi-close",
      });

      this.$router.push({
        name: "inicioPanel",
      });
    }
    window.scrollTo(0, 0);
  },
};
</script>
